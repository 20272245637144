import React, { useEffect, useState } from 'react';
import { Layout, Menu, Select, Spin } from "antd";
import { Link } from "react-router-dom";
import Icon, { UploadOutlined, LinkedinOutlined,DashboardOutlined,DollarOutlined } from '@ant-design/icons';
import SettingSVG from '../../assets/filters-icon.svg';
import CheckSVG from '../../assets/priority-rules-icon.svg';
import AdminSVG from '../../assets/occupation-admin-icon.svg';
import BudgetsSVG from '../../assets/budgets-icon.svg';
import GroupSVG from '../../assets/jobs-icon.svg';
import ConnectionSVG from '../../assets/socials.svg';
import ChartSVG from '../../assets/chart-icon.svg';
import SupplierSVG from '../../assets/manage-suppliers.svg';
import DistributionSVG from '../../assets/distribution-rules.svg';
import CandidateSVG from '../../assets/candidates.svg';
import QualityFilterSVG from '../../assets/quality-filter-icon.svg';
import TikTokSVG from '../../assets/TikTok.svg';
import Cookies from 'js-cookie';
import constants from '../../constants/constants';
import APIUtils from '../../api/APIUtils';
import WebLinks from '../../api/WebLinks';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { CurrencyContext } from '../../Context';

const { Sider } = Layout;

const Sidemenu = () => {
  const currencyContext=useContext(CurrencyContext);
  const [buyerList, setBuyerList] = useState([]);
  const [showAppReports, setShowAppReports] = useState(false);
  const [showClicksReport, setShowClicksReport] = useState(false);
  const [showSubBuyerAdmin, setShowSubBuyerAdmin] = useState(null);
  const [showQualityFilter, setShowQualityFilter] = useState(false);
  const history = useHistory();
  const isRecruiter= Cookies.get(constants.COOKIE_IS_RECRUITER);
  const [isHIAdmin,setIsHIAdmin]=useState(false);
  const [loader,setLoader]=useState(false);
  useEffect(() => {
    getBuyerDetails();
    BuyerListFetch();
  }, []);
  //fetch top menu buyer list
  const BuyerListFetch = async () => {
    let buyerListResponse = [];
    const response = await APIUtils.apiCall('GET', WebLinks.SubBuyerList + Cookies.get(constants.COOKIE_LOGGED_BUYER_ID));
    if (!response.isCatch && response.resultObj?.statusCode === "2000") {
      buyerListResponse = response.resultObj?.data?.map((item) => {
        return { selfPost: item.selfPost, value: item.subPartnerId, label: item.name, emailPost: item.emailPost, subBuyerAdmin: item.subBuyerAdmin }
      })
    }
    buyerListResponse = [{
      selfPost: Cookies.get(constants.COOKIE_LOGGED_BUYER_SELF_POSTED),
      value: parseInt(Cookies.get(constants.COOKIE_LOGGED_BUYER_ID)),
      label: Cookies.get(constants.COOKIE_LOGGED_BUYER_NAME),
      emailPost: Cookies.get(constants.COOKIE_EMAIL_POST),
      subBuyerAdmin: Cookies.get(constants.COOKIE_LOGGEDIN_IS_SUB_BUYER_ADMIN).toString() === 'true'
    }, ...buyerListResponse];
    buyerListResponse.sort((a, b) => a.label.localeCompare(b.label));
    setBuyerList(buyerListResponse);
    let loggedInBuyer=Cookies.get(constants.COOKIE_LOGGED_BUYER_NAME).toLowerCase();
    let SelectedBuyer=Cookies.get(constants.COOKIE_SELECTED_BUYER_NAME).toLowerCase();
    setIsHIAdmin(loggedInBuyer?.includes("hi admin")&&loggedInBuyer===SelectedBuyer);
  };
  const BuyerChanged = async (value) => {
    await Cookies.remove(constants.COOKIE_CANDIDATE_SEARCH);
    await Cookies.set(constants.COOKIE_SELECTED_BUYER_ID, value);
    let buyerDetails = buyerList.find((item) => item.value === value);
    await Cookies.set(constants.COOKIE_SELECTED_BUYER_SELF_POSTED, buyerDetails?.selfPost)
    await Cookies.set(constants.COOKIE_SELECTED_BUYER_NAME, buyerDetails?.label);
    await Cookies.set(constants.COOKIE_EMAIL_POST, buyerDetails?.emailPost)
    await Cookies.set(constants.COOKIE_SELECTED_IS_SUB_BUYER_ADMIN, buyerDetails?.subBuyerAdmin)
    //reload the page to refresh buyer details on the open menu item eg. application report list
    window.location.reload();
  };
  const getBuyerDetails = async () => {
    setLoader(true);
    const response = await APIUtils.apiCall('GET', WebLinks.GetBuyerDetails + Cookies.get(constants.COOKIE_SELECTED_BUYER_ID));
    if (!response.isCatch && response.resultObj?.statusCode === "2000") {
      currencyContext.changeCurrencyValue(constants.CurrencyConstants[response.resultObj?.data?.cpaCurrency||"GBP"])
      setShowClicksReport(response.resultObj?.data?.clickPost);
      setShowAppReports(response.resultObj?.data?.showAppReports);
      setShowSubBuyerAdmin(response.resultObj?.data?.subBuyerAdmin);
      setShowQualityFilter(response.resultObj?.data?.manualQualityReview);
      await Cookies.set(constants.COOKIE_CLICK_POST, response.resultObj?.data?.clickPost)
      await Cookies.set(constants.COOKIE_SELECTED_IS_SUB_BUYER_ADMIN, response.resultObj?.data?.subBuyerAdmin)
      if (Cookies.get(constants.COOKIE_LOGGED_BUYER_ID) === Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)) {
        await Cookies.set(constants.COOKIE_LOGGEDIN_IS_SUB_BUYER_ADMIN, response.resultObj?.data?.subBuyerAdmin)
      }
      setLoader(false);
    } else {
      setShowSubBuyerAdmin(false);
      setLoader(false);
    }
  };
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      theme='light'
      className='lg:!bg-transparent !absolute lg:!relative top-0 z-40 h-screen lg:h-auto'
      width={255}
    >
      <Spin spinning={loader}>
      <div className='px-4 py-3 mb-4 bg-white'>
        <div><label>Logged in as:</label></div>
        <p className='mb-2.5'><strong>{Cookies.get(constants.COOKIE_LOGGED_BUYER_NAME)}</strong></p>
        <div><label>Showing Data For:</label></div>
        <Select
          showSearch
          placeholder="Buyers"
          defaultValue={parseInt(Cookies.get(constants.COOKIE_SELECTED_BUYER_ID))}
          className='w-full rounded-md border border-black'
          optionFilterProp="children"
          filterOption={(input, option) =>
            // console.log(
            //   "op",option
            // )
            // (option?.label ?? '').toLowerCase().includes(input.toLowerCase())||
            // (option?.Id??'').toLowerCase().includes(input.toLowerCase())
            option?.label?.toLowerCase()?.includes(input?.trim()?.toLowerCase()) ||
            option?.value?.toString()?.toLowerCase()?.includes(input?.trim()?.toLowerCase())
          }
          options={buyerList}
          onChange={(e) => BuyerChanged(e)}
        />
      </div>
      {isRecruiter===true||isRecruiter==="true"?
      <>
      {showSubBuyerAdmin ? (
        <Menu theme="light" mode="inline" defaultSelectedKeys={['15']}>
          {/* <Menu.Item key="15" icon={<Icon component={() => (<img src={GroupSVG} alt='icon' />)} />}>
            <Link to="/multi-job-admin">
              Multi Your Jobs
            </Link>
          </Menu.Item>           */}
          <Menu.Item key="16" icon={<Icon component={() => (<img src={CandidateSVG} alt='icon' />)} />}>
            <Link to="/multi-candidate-admin">
              Multi Candidate 
            </Link>
          </Menu.Item>
        </Menu>
      ):(
        <Menu theme="light" mode="inline" defaultSelectedKeys={['1']}>
          <Menu.Item key="1" icon={<Icon component={() => (<img src={GroupSVG} alt='icon' />)} />}>
            <Link to="/your-jobs">
              Your Jobs
            </Link>
          </Menu.Item>
          <Menu.Item key="10" icon={<Icon component={() => (<img src={CandidateSVG} alt='icon' />)} />}>
            <Link to="/candidates">
              Candidates
            </Link>
          </Menu.Item>
        </Menu>)
      }
      </>:
      <>
      {showSubBuyerAdmin ? (
        <Menu theme="light" mode="inline" defaultSelectedKeys={['13']}>
          {isHIAdmin?null:<>
          <Menu.Item key="multidashboard" icon={<DashboardOutlined />}>
            <Link to="/multi-dashboard">
              Multi Dashboard
            </Link>
          </Menu.Item>
          <Menu.Item key="15" icon={<Icon component={() => (<img src={GroupSVG} alt='icon' />)} />}>
            <Link to="/multi-job-admin">
              Multi Your Jobs
            </Link>
          </Menu.Item>
          <Menu.Item key="20" icon={<Icon component={() => (<img src={SettingSVG} alt='icon' />)} />}>
            <Link to="/multi-priority-filters">
              Master Priority Filter
            </Link>
          </Menu.Item>
          <Menu.Item key="21" icon={<Icon component={() => (<img src={CheckSVG} alt='icon' />)} />}>
            <Link to="/multi-priority-rules">
              Master Priority Rules
            </Link>
          </Menu.Item></>}
          <Menu.Item key="19" icon={<DollarOutlined />}>
            <Link to="/multi-cost-center">
              Multi Cost Center 
            </Link>
          </Menu.Item>
          <Menu.Item key="22" icon={<Icon component={() => (<img src={BudgetsSVG} alt='icon' />)} />}>
            <Link to="/multi-budgets">
              Multi Budgets
            </Link>
          </Menu.Item>
          <Menu.Item key="13" icon={<Icon component={() => (<img src={BudgetsSVG} alt='icon' />)} />}>
            <Link to="/multi-budget-admin">
              Multi Budgets Admin
            </Link>
          </Menu.Item>
          {isHIAdmin?null:
          <Menu.Item key="24" icon={<Icon component={() => (<img src={SupplierSVG} alt='icon' />)} />}>
            <Link to="/multi-supplier-admin">
              Multi Supplier Admin
            </Link>
          </Menu.Item>}
          {Cookies.get(constants.COOKIE_LOGGED_BUYER_ID) === Cookies.get(constants.COOKIE_SELECTED_BUYER_ID)?
          <>
            <Menu.Item key="17" icon={<Icon component={() => (<img src={ChartSVG} alt='icon' />)} />}>
            <Link to="/multi-click-report">
              Multi Click Report 
            </Link>
          </Menu.Item>
          <Menu.Item key="14" icon={<Icon component={() => (<img src={ChartSVG} alt='icon' />)} />}>
            <Link to="/multi-application-report">
              Multi Application Report
            </Link>
          </Menu.Item>
          </>:
            showClicksReport?
              <Menu.Item key="17" icon={<Icon component={() => (<img src={ChartSVG} alt='icon' />)} />}>
                <Link to="/multi-click-report">
                  Multi Click Report 
                </Link>
              </Menu.Item>:
              <Menu.Item key="14" icon={<Icon component={() => (<img src={ChartSVG} alt='icon' />)} />}>
                <Link to="/multi-application-report">
                  Multi Application Report
                  </Link>
              </Menu.Item>
          }
          {isHIAdmin?null:
          <Menu.Item key="16" icon={<Icon component={() => (<img src={CandidateSVG} alt='icon' />)} />}>
            <Link to="/multi-candidate-admin">
              Multi Candidate 
            </Link>
          </Menu.Item>}
        </Menu>
      ) : (
        <Menu theme="light" mode="inline" defaultSelectedKeys={['1']}>
          <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
            <Link to="/dashboard">
              Dashboard
            </Link>
          </Menu.Item>
          <Menu.Item key="1" icon={<Icon component={() => (<img src={GroupSVG} alt='icon' />)} />}>
            <Link to="/your-jobs">
              Your Jobs
            </Link>
          </Menu.Item>
          <Menu.Item key="0" icon={<Icon component={() => (<img src={SettingSVG} alt='icon' />)} />}>
            <Link to="/priority-filters">
              Priority Filter
            </Link>
          </Menu.Item>
          <Menu.Item key="15" icon={<Icon component={() => (<img src={CheckSVG} alt='icon' />)} />}>
            <Link to="/priority-rules">
              Priority Rules
            </Link>
          </Menu.Item>

          <Menu.Item key="2" icon={<Icon component={() => (<img src={BudgetsSVG} alt='icon' />)} />}>
            <Link to="/budgets">
              Budgets
            </Link>
          </Menu.Item>
          {Cookies.get(constants.COOKIE_IS_ADMIN) === "true" || Cookies.get(constants.COOKIE_IS_ADMIN) === true ?
            (<Menu.Item key="8" icon={<Icon component={() => (<img src={BudgetsSVG} alt='icon' />)} />}>
              <Link to="/budgets-admin">
                Budgets Admin
              </Link>
            </Menu.Item>)
            : null}
          {Cookies.get(constants.COOKIE_IS_ADMIN) === "true" || Cookies.get(constants.COOKIE_IS_ADMIN) === true ?
            (<Menu.Item key="11" icon={<Icon component={() => (<img src={AdminSVG} alt='icon' />)} />}>
              <Link to="/your-jobs-occupations">
                Occupation Admin
              </Link>
            </Menu.Item>)
            : null}
            {Cookies.get(constants.COOKIE_IS_ADMIN) === "true" || Cookies.get(constants.COOKIE_IS_ADMIN) === true ?
            (<Menu.Item key="12" icon={<Icon component={() => (<img src={AdminSVG} alt='icon' />)} />}>
              <Link to="/your-jobs-occupations-filter">
                Occupation Filter Admin
              </Link>
            </Menu.Item>)
            : null}
          <Menu.Item key="9" icon={<Icon component={() => (<img src={SupplierSVG} alt='icon' />)} />}>
            <Link to="/supplier">
              Manage Suppliers
            </Link>
          </Menu.Item>
          {Cookies.get(constants.COOKIE_IS_ADMIN) === "true" || Cookies.get(constants.COOKIE_IS_ADMIN) === true ?
          (<Menu.Item key="23" icon={<Icon component={() => (<img src={SupplierSVG} alt='icon' />)} />}>
            <Link to="/supplier-admin">
              Manage Suppliers Admin
            </Link>
          </Menu.Item>)
          :null}
          <Menu.Item key="3" icon={<Icon component={() => (<img src={DistributionSVG} alt='icon' />)} />}>
            <Link to="/distribution-rules">
              Distribution Rules
            </Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<UploadOutlined className='!text-lg' />}>
            <Link to="/history">
              Jobs Upload History
            </Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<Icon component={() => (<img src={ConnectionSVG} alt='icon' />)} />}>
            <Link to="/social">
              Facebook / Instagram
            </Link>
          </Menu.Item>
          <Menu.Item key="tiktok" icon={<Icon component={() => (<img src={TikTokSVG} alt='icon' className='w-6' />)} />}>
            <Link to="/tiktok">
              TikTok Promo
            </Link>
          </Menu.Item>
          {showClicksReport ? (
            <Menu.Item key="6" icon={<Icon component={() => (<img src={ChartSVG} alt='icon' />)} />}>
              <Link to="/click-report">
                Click Report
              </Link>
            </Menu.Item>
          ) :
          //  null}
          // {showAppReports ? 
          (
            <Menu.Item key="7" icon={<Icon component={() => (<img src={ChartSVG} alt='icon' />)} />}>
              <Link to="/application-report">
                Application Report
              </Link>
            </Menu.Item>
          ) 
          // : null
          }
          <Menu.Item key="10" icon={<Icon component={() => (<img src={CandidateSVG} alt='icon' />)} />}>
            <Link to="/candidates">
              Candidates
            </Link>
          </Menu.Item>
          {showQualityFilter ? (<Menu.Item key="16" icon={<Icon component={() => (<img src={QualityFilterSVG} alt='icon' />)} />}>
            <Link to="/quality-filtering">
              Quality Filtering
            </Link>
          </Menu.Item>) : null}
          <Menu.Item key="17" icon={<Icon component={() => (<img src={QualityFilterSVG} alt='icon' />)} />}>
            <Link to="/quality-filters">
              Quality Filters
            </Link>
          </Menu.Item>
        </Menu>
      )}
      </>}
      </Spin>
    </Sider>
  );
};

export default Sidemenu;
